import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import router from '@/router'

const {
  successfulOperationAlert, confirmOperation, failedOperationAlert,
} = handleAlerts()

export default function Roles() {
  const table = ref(null)

  const getEntityRoles = (entityId = '', params) => axiosInstances.portalUsers.get(`v1/roles/entity/${entityId}`, { params }).then(res => res.data).catch(() => [])

  const getRole = id => axiosInstances.portalUsers.get(`v1/role/${id}`).then(res => res.data.data)

  const addRole = role => axiosInstances.portalUsers.post('v1/role', role).then(() => {
    successfulOperationAlert('Role is created successfully')
    router.go(-1)
  })

  const editRole = role => axiosInstances.portalUsers.put(`v1/role/${router.currentRoute.params.roleId}`, role).then(() => {
    successfulOperationAlert('Role is updated successfully')
    router.go(-1)
  })

  const getRolePermissions = roleId => axiosInstances.portalUsers.get(`v1/role-permissions/${roleId}`).then(res => res.data.data || [])

  const assignRolePermissions = selectedPermissions => {
    axiosInstances.portalUsers.post(`v1/role-assign-permissions/${router.currentRoute.params.roleId}`, {
      permissions: selectedPermissions,
    }).then(() => {
      successfulOperationAlert('Permissions are assigned successfully')
    })
  }

  const revokeRolePermissions = revokedPermissions => {
    axiosInstances.portalUsers.post(`v1/role-revoke-permissions/${router.currentRoute.params.roleId}`, {
      permissions: revokedPermissions,
    }).then(() => {
      failedOperationAlert('Permissions are revoked successfully')
    })
  }

  const deleteRoleFromEntityRequest = roleId => {
    axiosInstances.portalUsers.delete(`v1/role/${roleId}`).then(() => {
      successfulOperationAlert('Role is deleted successfully')
      table.value.refresh()
    })
  }

  const confirmOperationRole = roleId => {
    confirmOperation().then(() => {
      deleteRoleFromEntityRequest(roleId)
    })
  }

  const deleteRole = roleId => {
    axiosInstances.portalUsers.get(`v1/role-users/${roleId}`).then(res => {
      if (res.data.data.length === 0) confirmOperationRole(roleId)
      else failedOperationAlert(`You can't delete this role because these users [${res.data.data.map(el => el.username).toString()}] assigned to it`)
    })
  }

  return {
    table, deleteRole, getEntityRoles, getRolePermissions, assignRolePermissions, revokeRolePermissions, getRole, addRole, editRole,
  }
}
