import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import handleAlerts from '../common/handleAlerts'

const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

export default function userPermissions() {
  const getUserPermissionsInEntity = (username, entityId, params = {}) => axiosInstances.portalUsers
    .get(`v1/permissions/${username}/${entityId}`, {
      params,
    }).then(res => res.data.data || [])

  const assignUserPermissions = (selectedPermissions, entityId) => {
    axiosInstances.portalUsers.post(`v1/user-assign-permissions/${router.currentRoute.params.username}`, {
      permissions: selectedPermissions,
      entityId,
    }).then(() => {
      successfulOperationAlert('Permissions are assigned successfully')
    })
  }

  const revokeUserPermissions = (revokedPermissions, entityId) => {
    axiosInstances.portalUsers.post(`v1/user-revoke-permissions/${router.currentRoute.params.username}`, {
      permissions: revokedPermissions,
      entityId,
    }).then(() => {
      failedOperationAlert('Permissions are revoked successfully')
    })
  }

  return { getUserPermissionsInEntity, assignUserPermissions, revokeUserPermissions }
}
