<template>
  <div>
    <h4 class="mb-1">
      User: {{ $route.params.username }}
    </h4>

    <b-form-checkbox-group
      :checked="selectedGroups"
      @change="onSelectGroup"
    >
      <b-table
        :items="availablePermissionsGrouped"
        :fields="fields"
        responsive
      >
        <template #cell(groupName)="{item}">
          <span class="text-capitalize">{{ item.groupName }}</span>
        </template>
        <template #cell(selected)="{ item }">
          <b-form-checkbox
            :id="`${item.groupName}-checkbox`"
            :value="item.groupName"
          />
        </template>
        <template #cell(show_details)="row">
          <feather-icon
            v-if="row.detailsShowing"
            icon="ArrowUpCircleIcon"
            size="21"
            class="text-primary pointer"
            @click="row.toggleDetails"
          />
          <feather-icon
            v-else
            icon="ArrowDownCircleIcon"
            size="21"
            class="text-primary pointer"
            @click="row.toggleDetails"
          />
        </template>
        <template #row-details="{item}">
          <b-form-checkbox-group
            :checked="selectedPermissions"
            :options="item.permissions"
            value-field="name"
            text-field="displayName"
            disabled-field="disabled"
            stacked
            class="permissions-checkbox p-2 border rounded text-capitalize"
            @change="onSelectPermission"
          />
        </template>
      </b-table>
    </b-form-checkbox-group>
  </div>
</template>

<script>

export default {
  name: 'PermissionsTable',
  props: {
    selectedPermissions: { type: Array, default: () => [] },
    selectedGroups: { type: Array, default: () => [] },
    availablePermissionsGrouped: { type: Array, default: () => [] },
    entityId: { type: Number, default: null },
  },
  data() {
    return {
      fields: ['selected', 'groupName', 'show_details'],
    }
  },
  methods: {
    onSelectPermission(newPermissions) {
      const revokedPermissions = this.getDifferenceBetweenArrays(this.selectedPermissions, newPermissions)
      this.$emit('update:selectedPermissions', newPermissions)

      if (revokedPermissions.length) {
        this.$emit('revokePermissions', revokedPermissions, this.entityId)
      } else {
        const lastPermissionSelected = [newPermissions.at(-1)]
        this.$emit('assignPermissions', lastPermissionSelected, this.entityId)
      }
    },
    onSelectGroup(newPermissionsGroups) {
      const revokedPermissionsGroups = this.getDifferenceBetweenArrays(this.selectedGroups, newPermissionsGroups)

      this.$emit('update:selectedGroups', newPermissionsGroups)

      if (revokedPermissionsGroups.length) {
        const groupName = revokedPermissionsGroups[0]
        this.revokePermissionsGroupByName(groupName)
      } else {
        const groupName = newPermissionsGroups.at(-1)
        this.assignPermissionsGroupByName(groupName)
      }
    },
    assignPermissionsGroupByName(selectedPermissionGroupName) {
      const lastSelectedGroupPermissions = this.getGroupPermissionsByName(selectedPermissionGroupName)
      const availablePermissions = this.availablePermissionsGrouped.map(group => this.getGroupPermissionsByName(group.groupName)).flat()
      const availableAndSelectedPermissionsIntersection = this.getArraysIntersection(availablePermissions, this.selectedPermissions)
      const newSelectedPermissions = [...new Set([...availableAndSelectedPermissionsIntersection, ...lastSelectedGroupPermissions])]

      this.$emit('update:selectedPermissions', newSelectedPermissions)
      this.$emit('assignPermissions', newSelectedPermissions, this.entityId)
    },
    revokePermissionsGroupByName(revokedPermissionsGroupName) {
      const unselectedGroupPermissions = this.getGroupPermissionsByName(revokedPermissionsGroupName)
      const newSelectedPermissions = this.getDifferenceBetweenArrays(this.selectedPermissions, unselectedGroupPermissions)

      this.$emit('update:selectedPermissions', newSelectedPermissions)
      this.$emit('revokePermissions', unselectedGroupPermissions, this.entityId)
    },
    getDifferenceBetweenArrays(oldPermissions, newPermissions) {
      return oldPermissions.filter(permission => !newPermissions.includes(permission))
    },
    getArraysIntersection(oldPermissions, newPermissions) {
      return oldPermissions.filter(permission => newPermissions.includes(permission))
    },
    getGroupPermissionsByName(name) {
      return this.getGroupByName(name).permissions.map(permission => permission.name)
    },
    getGroupByName(name) {
      return this.availablePermissionsGrouped.find(group => group.groupName === name)
    },
  },
}
</script>
<style lang="scss">
.pointer{
  cursor:pointer
}
.permissions-checkbox{
  .custom-control:not(:last-child) {
    margin-bottom: 1em;
 }
}
</style>
